/* .ideation-tooltip {
  position: relative;
  margin-top: 8px;
} */

.tooltip-arrow {
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #E6FFE6; 
}
