@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Manrope';
}

h1{
  @apply text-[45px] !important
}

h2{
  @apply text-[40px] !important
}

h3{
  @apply text-[36px] !important
}

h4{
  @apply text-[32px] !important
}

h5{
  @apply text-[28px] !important
}

h6{
  @apply text-[22px] !important
}

a{
  @apply
  no-underline
}


.otp-input-container {
  display: flex;
  justify-content: space-between;
}

.otp-input {
  width: 67px;
  height: 67px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  @apply
  text-[32px]
  font-semibold
}

.otp-input:focus {
  outline: none;
  border-color: #aaa;
  box-shadow: 0 0 5px #aaa;
}

.tilt-box{
  padding: 16px;
  border-radius: 0px 30px 0px 30px;
  @apply
  w-full h-full
}

.progress-bar {
  width: 100%;
  height: 9px;
  border-radius: 30px;
  overflow: hidden;
  @apply bg-black50
  border border-blue-200
}

.progress {
  height: 100%;
  border-radius: 30px;
  transition: width 0.5s ease;
  @apply bg-blue-600 !important
}

button:disabled{
  cursor: not-allowed;
  opacity: 70%;
}